
import { Vue, Options } from 'vue-class-component';
import WorkflowLayout from '@/lib/layouts/WorkflowLayout.vue';
import { CopdMeasurementPayload, Patient, TemperatureUnits } from '@/models';
import { BaseButton, BaseNumberInput, BaseSelect } from '@/lib/components';
import { IValidationError, IPredefinedTerminologyList, IPredefinedTerminologyScaleList } from '@/lib';
import {
  predefinedTerminologyListFactory,
  predefinedTerminologyScaleListFactory
} from '@/lib/helpers/terminology.helper';
import {
  CopdProgramMeasurementService,
  CopdTerminologyService,
  HealthRecordScaleService,
  CopdModifiedAnthonisenHealthRecordService
} from '@/services/api';
import { Pathways, recordUserEvent } from '@/helpers/aws.helper';

@Options({
  props: {
    patient: {
      type: Object,
      required: true
    },
    programId: {
      type: String,
      required: true
    },
    organisationId: {
      type: String,
      required: true
    }
  },
  components: {
    WorkflowLayout,
    BaseButton,
    BaseSelect,
    BaseNumberInput
  }
})
export default class CopdNewMeasurementPage extends Vue {
  organisationId!: string;
  programId!: string;
  patient!: Patient;

  validationErrors: IValidationError | null = null;

  copdTerminologyService = new CopdTerminologyService();
  healthRecordScaleService = new HealthRecordScaleService();
  copdAnthonisenService = new CopdModifiedAnthonisenHealthRecordService();
  copdProgramMeasurementService = new CopdProgramMeasurementService(this.programId);

  oxygenList: IPredefinedTerminologyList = predefinedTerminologyListFactory([]);
  borgList: IPredefinedTerminologyScaleList = predefinedTerminologyScaleListFactory([]);
  breathlessnessList: IPredefinedTerminologyScaleList = predefinedTerminologyScaleListFactory([]);
  sputumAmountList: IPredefinedTerminologyScaleList = predefinedTerminologyScaleListFactory([]);
  sputumColorList: IPredefinedTerminologyScaleList = predefinedTerminologyScaleListFactory([]);
  wheezeList: IPredefinedTerminologyScaleList = predefinedTerminologyScaleListFactory([]);
  coughList: IPredefinedTerminologyScaleList = predefinedTerminologyScaleListFactory([]);
  soreThroatList: IPredefinedTerminologyScaleList = predefinedTerminologyScaleListFactory([]);
  runnyNoseList: IPredefinedTerminologyScaleList = predefinedTerminologyScaleListFactory([]);

  form: any = {
    pulse_rate: null,
    spo2: null,
    method_of_oxygen_delivery: null,
    flow_rate: null,
    temperature: null,
    respiration_rate: null,
    breathlessness_scale: null,
    criteria_breathlessness: null,
    criteria_sputum_production: null,
    criteria_sputum_colour: null,
    criteria_cough: null,
    criteria_wheeze: null,
    criteria_sore_throat: null,
    criteria_congested_runny_nose: null
  };

  submitting = false;

  get steps() {
    return [this.$t('custom.uhb.copd.patient-req') as string];
  }

  get step() {
    // We want the URL param to be 1-based, but the value in the component to be zero-based
    return Number(this.$route.query.step || 1) - 1;
  }

  get flowRateOptions(): string[] {
    return ['0.5', '1', '2', '3', '4', '5', '6', '7'];
  }

  async fetchOxygenDeliveryMethods() {
    try {
      const response = await this.copdTerminologyService.getMethodOfOxygenDelivery();
      this.oxygenList = predefinedTerminologyListFactory(response.data);
    } catch (e) {
      this.handleResponseError(e);
    }
  }

  async fetchBorgScale() {
    this.borgList = predefinedTerminologyScaleListFactory((await this.healthRecordScaleService.getScalesBorg()).data);
  }

  async fetchAnthonisenCriteriaSelectionOptions() {
    this.breathlessnessList = predefinedTerminologyScaleListFactory(
      (await this.copdAnthonisenService.getBreathlessness()).data
    );

    this.sputumAmountList = predefinedTerminologyScaleListFactory(
      (await this.copdAnthonisenService.getSputumProd()).data
    );
    this.sputumColorList = predefinedTerminologyScaleListFactory(
      (await this.copdAnthonisenService.getSputumColor()).data
    );

    this.wheezeList = predefinedTerminologyScaleListFactory((await this.copdAnthonisenService.getWheeze()).data);

    this.coughList = predefinedTerminologyScaleListFactory((await this.copdAnthonisenService.getCough()).data);

    this.soreThroatList = predefinedTerminologyScaleListFactory(
      (await this.copdAnthonisenService.getSoreThroat()).data
    );

    this.runnyNoseList = predefinedTerminologyScaleListFactory((await this.copdAnthonisenService.getRunnyNose()).data);
  }

  clearError(key: string) {
    this.validationErrors = {
      message: this.validationErrors?.message || '',
      errors: {
        ...(this.validationErrors?.errors || {}),
        [key]: [] as string[]
      }
    };
  }

  clearErrors() {
    this.validationErrors = null;
  }

  back() {
    if (this.step <= 0) {
      this.$router.back();
      return;
    }

    // TODO Clear any errors in the form
    this.clearErrors();

    // @ts-ignore
    this.$router.push({
      ...this.$route,
      query: {
        ...this.$route.query,
        step: String(this.step) // Add 2 because the URL param is 1-based
      }
    });
  }

  mounted() {
    this.fetchOxygenDeliveryMethods();
    this.fetchBorgScale();
    this.fetchAnthonisenCriteriaSelectionOptions();

    this.recordEvent('started recording patient measurements');
  }

  handleResponseError(e: any) {
    if (e.response.status === 422) {
      this.validationErrors = {
        message: '',
        errors: e.response.data.errors
      };
    } else {
      this.validationErrors = {
        message: e.response.data.message,
        errors: {}
      };
    }
  }

  get enableFlowRate() {
    // If method_of_oxygen_delivery not null or not room air
    return !!this.form.method_of_oxygen_delivery && this.form.method_of_oxygen_delivery !== '722742002';
  }

  recordEvent(event: string) {
    recordUserEvent(event, Pathways.COPD, this.patient.id);
  }

  preparePayload(): CopdMeasurementPayload {
    return {
      pulse: {
        rate: this.form.pulse_rate
      },
      pulse_oximetry: {
        spo2: this.form.spo2,
        method_of_oxygen_delivery: this.oxygenList.find(this.form.method_of_oxygen_delivery),
        ...(this.enableFlowRate && { flow_rate: this.form.flow_rate })
      },
      ...(this.form.temperature && {
        body_temperature: {
          temperature: {
            magnitude: this.form.temperature,
            units: TemperatureUnits.Celsius
          }
        }
      }),
      respiration: {
        rate: this.form.respiration_rate
      },
      borg_breathlessness_scale: {
        amount_of_breathlessness: this.borgList.find(this.form.breathlessness_scale)
      },
      uhb_modified_anthonisen_criteria: {
        breathlessness: this.breathlessnessList.find(this.form.criteria_breathlessness),
        sputum_production: this.sputumAmountList.find(this.form.criteria_sputum_production),
        sputum_colour: this.sputumColorList.find(this.form.criteria_sputum_colour),
        cough: this.coughList.find(this.form.criteria_cough),
        wheeze: this.wheezeList.find(this.form.criteria_wheeze),
        sore_throat: this.soreThroatList.find(this.form.criteria_sore_throat),
        congested_runny_nose: this.runnyNoseList.find(this.form.criteria_congested_runny_nose)
      }
    };
  }

  async complete() {
    this.submitting = true;

    try {
      this.clearErrors();
      await this.copdProgramMeasurementService.create(this.preparePayload());
      this.recordEvent('completed recording patient measurements');
      await this.$router.push({
        name: 'patient-virtual-ward',
        params: { patientId: this.patient.id, programId: this.programId, organisationId: this.organisationId }
      });
    } catch (e) {
      this.handleResponseError(e);
    } finally {
      this.submitting = false;
    }
  }
}
